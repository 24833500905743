<template>
  <v-row class="right-panel">
    <v-col v-if="isLoading">
      <v-card outlined class="dialog-cls panel-main">
        <v-card-title class="header-title">
          <router-link
            class="products-title"
            :to="{ path: isLogin ? '/products' : '/product-list' }"
            >PRODUCTS</router-link
          >
          <span class="newsfeed-title mr-1 ml-1">|</span>
          <div class="newsfeed-title">{{ product.name }}</div>
          <v-spacer></v-spacer>
          <div class="panel-right-options ml-auto">
            <router-link
              :to="{ path: isLogin ? '/products' : '/product-list' }"
            >
              <v-icon
                style="cursor: pointer"
                color="white"
                class="mr-3 vuetify-icon"
                size="26"
                >mdi-close-circle-outline</v-icon
              >
            </router-link>
            <router-link
              v-if="product.priviousId"
              :to="{ path: '/products/view/' + product.priviousId._id }"
            >
              <v-icon
                style="cursor: pointer"
                color="white"
                class="mr-1 vuetify-icon"
                size="26"
                >mdi-arrow-left-circle-outline</v-icon
              >
            </router-link>
            <v-icon v-else disabled color="white" class="mr-1" size="26"
              >mdi-arrow-left-circle-outline</v-icon
            >
            <router-link
              v-if="product.nextId"
              :to="{ path: '/products/view/' + product.nextId._id }"
            >
              <v-icon
                color="white"
                class="ml-1 vuetify-icon"
                style="cursor: pointer"
                size="26"
                >mdi-arrow-right-circle-outline</v-icon
              >
            </router-link>
            <v-icon v-else disabled color="white" class="ml-1" size="26"
              >mdi-arrow-right-circle-outline</v-icon
            >
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-7 scroll-box">
          <v-row>
            <v-col
              :cols="$vuetify.breakpoint.mdAndUp ? '' : 12"
              xs="12"
              sm="12"
              md="6"
              lg="4"
              xl="4"
            >
              <div class="imgSectionCls d-flex">
                <img :src="productImage" class="my-auto" />
              </div>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mdAndUp ? '' : 12"
              xs="12"
              sm="12"
              md="6"
              lg="8"
              xl="8"
            >
              <v-row>
                <v-col class="pa-3 pt-0">
                  <v-card-title class="product-title pa-0">
                    {{ product.name }}®
                  </v-card-title>
                  <v-card-subtitle class="pa-1 mt-n2">
                    <div class="product-stock" v-if="product.stockStatus">
                      {{ product.stockStatus }}
                    </div>
                  </v-card-subtitle>
                </v-col>
              </v-row>
              <v-card outlined class="dialog-cls">
                <v-row class="pa-4">
                  <v-col xs="12" sm="12" md="12" lg="6" xl="6" class="px-1">
                    <v-card-title class="general-title"
                      >MANUFACTURER / PRODUCT NAME</v-card-title
                    >
                    <v-card-subtitle class="dialog-text">
                      <span v-if="product.manufacturersObj">{{
                        product.manufacturersObj.name
                      }}</span>
                      <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                        >/</span
                      >
                      <span>{{ product.name }}</span>
                    </v-card-subtitle>
                    <v-card-title class="general-title pt-9"
                      >DELIVERY METHOD</v-card-title
                    >
                    <v-card-subtitle class="dialog-text">
                      {{ product.deliveryMethod }}
                    </v-card-subtitle>
                  </v-col>

                  <v-col
                    xs="12"
                    sm="12"
                    md="12"
                    :lg="product.qrCodeUrl ? 3 : 6"
                    :xl="product.qrCodeUrl ? 3 : 6"
                    class="px-1"
                  >
                    <v-card-title class="general-title"
                      >CANNABINOIDS</v-card-title
                    >
                    <v-card-subtitle class="dialog-text">
                      {{ product.cannabinoid || "-" }}
                    </v-card-subtitle>
                    <v-card-title class="general-title pt-9"
                      >PRODUCT TYPE</v-card-title
                    >
                    <v-card-subtitle class="dialog-text">
                      {{ product.productType }}
                    </v-card-subtitle>
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    sm="12"
                    md="12"
                    lg="3"
                    xl="3"
                    v-if="product.qrCodeUrl"
                    :class="
                      $vuetify.breakpoint.mdAndDown ? 'text-left' : 'text-right'
                    "
                  >
                    <img
                      :src="product.qrCodeUrl"
                      alt="Qr Code"
                      width="170"
                      class="pt-2"
                    />
                  </v-col>

                  <v-col xs="12" sm="12" md="12" lg="6" xl="6" class="px-1">
                    <v-card-title class="general-title"
                      >FORMULATION</v-card-title
                    >
                    <v-card-subtitle class="dialog-text">{{
                      product.formulation
                    }}</v-card-subtitle>
                  </v-col>

                  <!-- <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <v-card-title class="general-title">API</v-card-title>
                    <v-card-subtitle class="dialog-text">{{
                      product.api
                    }}</v-card-subtitle>
                  </v-col> -->
                  <v-col xs="12" sm="12" md="12" lg="6" xl="6" class="px-1">
                    <v-card-title class="general-title"
                      >Full Spectrum</v-card-title
                    >
                    <v-card-subtitle class="dialog-text">{{
                      product.fullSpectrum ? "Yes" : "No"
                    }}</v-card-subtitle>
                  </v-col>

                  <v-col xs="12" sm="12" md="12" lg="6" xl="6" class="px-1">
                    <v-card-title class="general-title"
                      >PROFILE TYPE</v-card-title
                    >
                    <v-card-subtitle class="dialog-text">
                      {{ product.profileType || "-" }}
                    </v-card-subtitle>
                  </v-col>
                  <v-col xs="12" sm="12" md="12" lg="6" xl="6" class="px-1">
                    <v-card-title class="general-title">COA</v-card-title>
                    <v-card-subtitle class="dialog-text">
                      <a class="fixed-length">{{ product.coa || "-" }}</a>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <document-list
            v-if="documents.length > 0"
            :documents="documents"
            :productsData="product"
          ></document-list>

          <v-row>
            <v-col
              v-if="
                product.cautions ||
                  product.interactions ||
                  product.sideEffects ||
                  product.dosage ||
                  product.indications ||
                  product.contraIndications ||
                  product.allergyAndCrossSensitivity ||
                  product.pregnancy ||
                  product.brestFeeding ||
                  product.hepaticImpairment ||
                  product.renalImpairment ||
                  product.monitoringRequirements ||
                  product.strainCultivar
              "
            >
              <v-card outlined class="dialog-cls pa-4">
                <v-card elevation="0" v-if="product.cautions">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >CAUTIONS</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.cautions
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card elevation="0" class="my-1" v-if="product.interactions">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >INTERACTIONS</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.interactions
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card elevation="0" class="my-1" v-if="product.sideEffects">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >SIDE-EFFECTS</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.sideEffects
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card elevation="0" class="my-1" v-if="product.dosage">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >DOSAGE</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.dosage
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card elevation="0" class="my-1" v-if="product.indications">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >INDICATIONS</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.indications
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card
                  elevation="0"
                  class="my-1"
                  v-if="product.contraIndications"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >CONTRA-INDICATIONS</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.contraIndications
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card
                  elevation="0"
                  class="my-1"
                  v-if="product.allergyAndCrossSensitivity"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >ALLERGY AND CROSS-SENSITIVITY</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.allergyAndCrossSensitivity
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card elevation="0" class="my-1" v-if="product.pregnancy">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >PREGNANCY</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.pregnancy
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card elevation="0" class="my-1" v-if="product.brestFeeding">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >BREST FEEDING</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.brestFeeding
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card
                  elevation="0"
                  class="my-1"
                  v-if="product.hepaticImpairment"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >HEPATIC IMPAIRMENT</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.hepaticImpairment
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card
                  elevation="0"
                  class="my-1"
                  v-if="product.renalImpairment"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >RENAL IMPAIRMENT</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.renalImpairment
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card
                  elevation="0"
                  class="my-1"
                  v-if="product.monitoringRequirements"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >MONITORING REQUIREMENTS</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.monitoringRequirements
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card
                  elevation="0"
                  class="my-1"
                  v-if="product.strainCultivar"
                >
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="general-title"
                        >STRAIN / CULTIVAR</v-list-item-title
                      >
                      <v-list-item-subtitle class="product-sub-desc">
                        <span v-if="product.manufacturersObj">{{
                          product.manufacturersObj.name
                        }}</span>
                        <span v-if="product.manufacturersObj" class="ml-1 mr-1"
                          >/</span
                        >
                        <span>{{ product.name }}</span>
                      </v-list-item-subtitle>
                      <v-card-text class="product-desc">{{
                        product.strainCultivar
                      }}</v-card-text>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
          <product-chart
            v-if="
              $vuetify.breakpoint.lgAndUp &&
                product.parameters &&
                product.parameters.length > 0 &&
                !checkAllResultValZero
            "
            :productsData="product"
          ></product-chart>
          <small-product-chart
            v-if="
              $vuetify.breakpoint.mdAndDown &&
                product.parameters &&
                product.parameters.length > 0 &&
                !checkAllResultValZero
            "
            :productsData="product"
          ></small-product-chart>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-else>
      <v-card outlined class="dialog-cls">
        <v-container style="height: 600px">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="general-title-sm text-center" cols="12"
              >Loading... Please wait</v-col
            >
            <v-col cols="6">
              <v-progress-linear
                color="#344452"
                indeterminate
                rounded
                height="10"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ProductService } from "@/services";
import store from "@/store";

export default {
  name: "Detail",
  components: {
    DocumentList: () => import("./DocumentList.vue"),
    ProductChart: () => import("./ProductChart.vue"),
    SmallProductChart: () => import("./ProductChartSmall.vue")
  },
  data() {
    return {
      dialog: false,
      product: [],
      isLoading: false,
      isLogin: false
    };
  },
  computed: {
    documents() {
      return this.product.media.filter(media => media.mediaType === "doc");
    },
    productImage() {
      const productImages = this.product.media.filter(
        media => media.mediaType === "image"
      );
      if (productImages && productImages.length > 0) {
        return productImages[0].url;
      } else {
        return "https://growbiotech-prod.s3.eu-west-2.amazonaws.com/1606301813454.png";
      }
    },
    checkAllResultValZero() {
      let res = false;
      const arrayOfResult = this.product.parameters.map(param =>
        param.result !== undefined ? param.result : "0"
      );
      res = arrayOfResult.every(result => result === "0");
      return res;
    }
  },
  async mounted() {
    this.isLogin = store.state.user.isUserLoggedIn;
    const product = await ProductService.get(this.$route.params.id);
    this.product = product.data.data[0];
    this.isLoading = true;
  }
};
</script>

<style lang="scss" scoped>
.close-icon:hover {
  color: rgba(0, 0, 0, 0.54) !important;
}
.products-title {
  color: #fff;
}
.newsfeed-title {
  text-transform: uppercase;
}
.products-title:hover {
  color: #fcae00 !important;
}
.dialog-cls {
  .scroll-box {
    max-height: 620px;
    height: 620px;
    overflow-y: auto;
  }
}
.card-box {
  border: 1px solid #d6e0ef;
  position: relative;
  height: 100%;
  .image-box {
    width: 100%;
    display: block;
    overflow: hidden;
    background-position: center;
    background-size: contain;
    min-height: 270px;
    img {
      width: 100%;
      height: 270px;
      object-position: center;
      object-fit: fill;
      display: block;
      // @include transition;
    }
  }
  .image-box:hover {
    overflow: hidden;
    img {
      transform: scale(1.1);
      opacity: 0.9;
    }
  }
}
</style>
